.errorText {
    margin: 0 60px;
    font-size: 130%;
}

.textDivs {
    margin: 20px 0 0 0;
}

#texts {
    font-size: 125%;
    color: blue;
}

.regGreen {
    font-size: 130%;
    color: green;
    margin: 10px 0;
}