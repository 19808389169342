.containerRegisterAssoc{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.nastroyka{
    font-size: 200%;
}
.selectContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.regErr{
    color: red;
}
.typeAssoc{
    font-size: 130%;
    font-weight: 700;
}
.settingSel{
    width: 80%;
}