* {
  background-color: #f6f6f6;
  color: rgb(0, 0, 0);
  font-family: "Roboto Condensed", sans-serif;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

html {
  height: 100%;
  border-radius: 50px;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
  text-align: center;
}

ul {
  list-style: none;
}

li {
  display: flex;
}

input:checked + span {
  text-decoration: line-through;
}
