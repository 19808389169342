.name {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 100%;
}
.body {
  width: 100%;
  max-width: 700px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.bodyFon {
  background-color: #f6f6f6;
  position: fixed;

  max-width: 700px;
  height: 100%;
  box-shadow: 0px -3px 8px 0px rgba(34, 60, 80, 0.2);
  z-index: -1;
}

#loading {
  position: absolute;
  background-color: #03758e4f;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

header {
  z-index: 50;
  display: flex;
  position: fixed;
  bottom: 0px;
  width: 100%;
  box-shadow: 0px -3px 8px 0px rgba(34, 60, 80, 0.2);
}

header ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 0;
}
@media (min-width: 700px) {
  /* Стили для экранов шире 700px */
  .header ul {
    justify-content: space-evenly;
  }
}

header ul svg {
  font-size: 150%;
}

.headers {
  background-color: transparent;
}

.checkboxSlider {
  position: relative;
  width: 30px;
  border: 2px solid grey;
  border-radius: 10px;
  display: flex;
  justify-content: baseline;
  align-items: center;
}

#checkbox {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

#checkboxSlider1 {
  justify-content: end;
  border-color: rgb(13, 126, 13);
}

#checkboxSlider2 {
  justify-content: baseline;
}

.borderRed {
  border: 2px solid red;
}

#shar1 {
  background-color: rgb(18, 154, 18);
  border-radius: 50%;
  margin: 1px;
  width: 15px;
  height: 15px;
}

#shar2 {
  border-radius: 50%;
  background-color: gray;
  margin: 1px;
  width: 15px;
  height: 15px;
}

.teloForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input[type="button"] {
  color: white;
  font-size: 100%;
  border-radius: 5px;
  padding: 5px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;

  background: rgb(0, 172, 238);
  background: linear-gradient(
    0deg,
    rgba(0, 172, 238, 1) 0%,
    rgba(2, 126, 251, 1) 100%
  );
  border: none;
}

input[type="submit"] {
  background: rgb(193, 101, 221);
  background: linear-gradient(
    135deg,
    rgba(193, 101, 221, 1) 0%,
    rgba(102, 58, 240, 1) 34%
  );
  color: white;
  font-size: 110%;
  padding: 5px;
  transition: 0.5s;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

input[type="button"]:hover {
  background-color: rgb(88, 205, 170);
}

.classData {
  border: 2px solid blue;
  padding: 10px;
  margin: 10px 0;
}
