#textDiv div {
    margin: 20px 0;
}

.logOut {
    color: red;
}

.clickPassword {
    color: blue;
}