.setkaShowDataContainer {
  width: 100%;
}
.DataSetkiContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.gridCheckmarkContainer {
  position: absolute;
  right: 5px;
  z-index: 5;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.GridImCheckmark {
  border-radius: 5px;
  border: 1px solid black;
  padding: 2px;
}
.DataSetkiName {
  position: relative;
  background-color: transparent;
  border-radius: 5px;
  display: flex;
  justify-content: baseline;
  align-items: center;
  width: 80%;
  background-color: white;
  /* border: 1px solid black; */
  transform: skew(-8deg);
  margin: 0 0 0 7px;
}
#DataGridContaynerBlue {
  margin: 25px 0 0 0;
}
.DataGridNameBlue {
  box-shadow: 0px 3px 4px 0px rgba(0, 74, 203, 0.561);
}
.DataGridNameRed {
  box-shadow: 0px 3px 4px 0px rgba(255, 0, 0, 0.561);
}

.noskew {
  transform: skew(8deg);
}
#avaShowSetka {
  margin: 5px;
  width: 70px;
  height: 70px;
}

.dSetka {
  background-color: transparent;
  margin: 0;
  width: 60%;
  font-size: 80%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.dSetka p {
  margin: 0;
  background-color: transparent;
  margin: 1px 0;
}

#DataSetkiStrelkaNone {
  display: none;
}

.DataSetkiStrelkaUP {
  position: absolute;
  top: 48%;
  right: -36.2px;
  width: 30px;
  border: 3px solid #03748e;
  border-left: 0px solid #03748e;
  border-bottom: 0px solid #03748e;
  height: 60px;
  background-color: transparent;
}

.DataSetkiStrelkaDOWN {
  position: absolute;
  bottom: 48%;
  right: -28px;
  width: 30px;
  border: 3px solid #03748e;
  border-left: 0px solid #03748e;
  border-top: 0px solid #03748e;
  height: 60px;
  background-color: transparent;
}
.GiPodiumWinnerIcon {
  width: 40px;
  height: 40px;
  fill: #03748e;
  position: absolute;
  top: 25%;
  right: -45px;
}

.kzFlag {
  background-color: transparent;
}
.kzFlagGrid {
  background-color: white;
  position: absolute;
  top: 3px;
  right: -10px;
  background-color: transparent;
}
/* .kzFlag svg {
  background-color: transparent;
} */

.nameDataGrid {
  font-weight: 700;
}
.yearDataGrid {
  font-size: 80%;
  color: #616161;
}
.coachDataGrid {
  font-size: 85%;
  color: #676767;
}
.adressGrid {
  font-size: 70%;
  color: #616161;
}
.styleTextGrid {
  padding: 5px;
  font-size: 110%;
  font-weight: 700;
  background-color: red;
  color: white;
  border-radius: 5px;
  transform: skew(-10deg);
  width: 65px;
  margin: 20px 0 0 0;
}
