.ContainerChampInfo {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.blueBackgroundChamp {
  width: 100%;
  height: 120px;
  background-color: rgb(57, 165, 232);
  position: absolute;
  z-index: 0;
}
.nameChamp {
  font-size: 100%;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin: 13px 5px;
  display: inline-block;
  animation: fadeIn 1s ease-in-out;
  padding: 4px 0 0 0;
  border-bottom: 3px solid #ffffff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

#setttingIconChamp {
  background-color: transparent;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 150%;
}

/* стиль внутри турнира */
.HeaderChamp {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.HeaderChamp span {
  font-size: 90%;
}
.divBodyChamp {
  width: 90%;
  font-size: 100%;
  margin: 10px 0 0 0;
  line-height: 1.6;
  text-align: left;
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: transparent;
}
.divBodyChamp span div {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.divBodyChamp span {
  background-color: transparent;
}

#bodyChampInfoContainer {
  z-index: 1;
  margin: 10px 0 0 0;
  width: 95%;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(10px);
  border-radius: 15px;
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.472);
  border: 1px solid rgba(186, 186, 186, 0.61);
  font-family: Arial, sans-serif;
  text-align: center;
  background-color: transparent;
}
.avaContainerChamp {
  background-color: transparent;
}

#avaClubChampRemove {
  background-color: transparent;
  margin: 0;
}
#click {
  background-color: #000000;
  color: white;
}
.sportInfo {
  display: flex;
  justify-content: baseline;
  align-items: center;
  overflow-x: scroll;
  width: 97%;
  margin: 5px 0;
  background-color: transparent;
}

.nameChampCategory {
  border: 2px solid #000000;
  color: #000000;
  margin: 0 10px 0 0;
  padding: 2px 10px;
  border-radius: 7px;
  display: inline;
  white-space: pre;
  font-size: 90%;
  font-weight: 800;
}

.nameChampCategory:hover {
  background-color: #000000b6;
  color: white;
  cursor: pointer;
}

.SettingMyChampContainer {
  width: 95%;
  padding: 10px 0 0 0;
  background-color: transparent;
}

.inputColumn {
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#clickGenderMale {
  background-color: rgba(82, 164, 241, 0.249);
  border-color: rgb(0, 100, 194);
}
#clickGenderFemale {
  background-color: rgba(255, 0, 106, 0.217);
  border-color: rgb(255, 0, 25);
}
.champGender {
  font-size: 120%;
  background-color: transparent;
}
#clickGenderMaleIcon {
  fill: rgb(0, 100, 194);
}
#clickGenderFemaleIcon {
  fill: rgb(255, 0, 25);
}
.nameChampCategory {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PenChampCategory {
  fill: black;
  background-color: white;
  border-radius: 5px;
  padding: 3px;
  margin: 0 0 0 8px;
}
#champCategoryInput {
  border: 2px solid black;
  padding: 3px;
  font-size: 100%;
  border-radius: 10px;
}
.champPeopleIcon {
  background-color: transparent;
  fill: rgb(48, 153, 214);
  font-size: 140%;
  margin: 0 5px;
}
.champPeopleIconFemale {
  background-color: transparent;
  fill: rgb(255, 0, 25);
  font-size: 140%;
  margin: 0 5px;
}
.champPeopleLength {
  margin: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(48, 153, 214);
}

.champPeopleLengthFemale {
  margin: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgba(255, 0, 25, 0.842);
}

.champRemoveCategoryIcons {
  background-color: transparent;
}
.leftovers {
  width: 100%;
  height: 120px;
  background-color: transparent;
}

#reportPDFChampionship {
  font-size: 60%;
  font-weight: 1000;
  background-color: transparent;
}

.DeleteChampClick {
  background-color: rgba(255, 0, 0, 0.748);
  color: white;
  border-radius: 5px;
  position: absolute;
  top: 20px;
  left: 20px;
}
