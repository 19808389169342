.flex {
  z-index: 1;
  width: 100%;
}

.Form {
  width: 100%;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 100px 0;
}

form label {
  margin: 10px 0;
}

.yearsRadioLabel {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.yearsRadioLabel span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile {
  background-color: #f6f6f6;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
  align-items: center;
  width: 100%;
  z-index: 1;
}

.option {
  margin: 20px 0 0 0;
  background-color: transparent;
}

.option:hover .optiondiv {
  color: #000000;
  background-color: #b5b5b5;
}

.optiondiv {
  padding: 5px;
  cursor: pointer;
  background-color: #d4d4d4;
  border-radius: 5px;
  transition: 0.5s;
}

.optionIcon {
  background-color: transparent;
  font-size: 25px;
}
.avaContainer {
  background-color: transparent;
}
.position {
  background-color: transparent;
  color: #18c0dd;
  font-size: 70%;
  font-weight: 1000;
  border-radius: 5px;
  padding: 0 5px;
  margin: 0 0 0 5px;
  text-align: center;
  align-items: center;
  border: 2px solid #18c0dd;
  letter-spacing: 0px;
}
.positionMini {
  background-color: transparent;
  color: #18c0dd;
  font-size: 85%;
  font-weight: 1000;
  border-radius: 5px;
  padding: 0 10px;
  text-align: center;
  align-items: center;
  border: 2px solid #18c0dd;
  letter-spacing: 0px;
  margin: 3px 0 0 0;
  padding: 0 5px;
}

.ava {
  margin: 10px 0 0 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
  overflow: hidden;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.avaImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.addFoto {
  position: absolute;
  z-index: 1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}

.sloyAva {
  width: 100px;
  height: 100px;
  background-color: transparent;
  position: relative;
}

.inputFile {
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 10px 0 0 0;
  z-index: 5;
  padding: 6px 0;
}

.inputFile span {
  position: absolute;
  display: inline-block;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  vertical-align: middle;
  color: rgb(255 255 255);
  text-align: center;
  border-radius: 50%;
  background-color: #00b6de;
  line-height: 22px;
  height: 30px;
  width: 30px;
  padding-top: 3px;
  box-sizing: border-box;
  border: none;
  margin: 0;
  transition: background-color 0.2s;
  top: 99px;
  right: 0px;
}

.subscribe {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subscribe div {
  background-color: transparent;
}

.nameLogin {
  background-color: transparent;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 30;
}

.nameL {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  height: 30px;
  width: 99%;
}

.fonBisness {
  background-color: transparent;
  width: 100%;
  height: 140px;
}

.fixedFonBisness {
  position: fixed;
  top: 30px;
  width: 100%;
  height: 140px;
  background-color: antiquewhite;
  z-index: 0;
}

.fonImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.headerUL {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 0;
  background-color: transparent;
  margin: 0;
  padding: 5px 0;
}

.headerUL li {
  font-size: 140%;
  background-color: transparent;
}

.miniMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 5;
  background-color: white;
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  width: 100%;
}

.nameLogin div {
  font-size: 110%;
  font-weight: bold;
  letter-spacing: 3px;
}

.subscribe .optiondiv {
  margin: 0px 0 0 0;
}

.subscribe div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px 0 0 0;
}

.subscribe div div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
  font-size: 90%;
}

a {
  background-color: transparent;
  text-decoration: none;
  color: black;
  font-size: 88%;
}

.characteristic {
  z-index: 5;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 20px 0;
}

.characteristic div {
  margin-left: 10px;
}

.otjim {
  font-size: 100%;
  background-color: transparent;
}

.body {
  background-color: white;
  z-index: 5;
  width: 100%;
}

.uprazhnenie,
.uprazhnenie div,
h2,
.iconTren,
.transparent,
.characteristic div {
  background-color: transparent;
}

#iconHeadeLi {
  background-color: white;
}

.uprazhnenie {
  display: flex;
  justify-content: space-around;
}

.otchetTren {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.otchetTren div {
  margin: 5px 0;
}

h2 {
  color: #18c0dd;
  margin: 0 0 5px 0;
}

.RRR {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f6f6;
  width: 100%;
}

.linkChamp {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resultMap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

.Result {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  margin: 70px 0 20px 0;
  border-radius: 10px;
  padding: 50px 2% 2% 2%;
  border: 1px solid black;
  z-index: 5;
  box-shadow: 0 2px 6px #4242427e;
}

.Result span {
  margin: 3px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.Result span,
.Result span div {
  background-color: transparent;
}

.resultMedal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.resultMedalDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin: 5px 0;
}

.resultMedalDiv div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconMedalRes {
  background-color: transparent;
  fill: gold;
}

.ball {
  margin: 0 0 0 20px;
}

.f {
  margin: 0 0 0 5px;
}

.flagAbsolut {
  position: absolute;
  right: 0px;
  background-color: transparent;
  top: 70px;
}

.fotoChampionship {
  top: 14.9px;
  width: 110px;
  height: 110px;
  position: absolute;
  left: 50%;
  translate: -50%;
  border-radius: 50%;
  background-color: white;
  border: 1px solid black;
  border-bottom-color: white;
  border-left-color: white;
  z-index: 1;
  transform: rotate(-45deg);
}

.fotoCHdiv {
  background-color: transparent;
  transform: rotate(45deg);
  width: 110px;
  height: 110px;
  border-radius: 50%;
}

.fotoImgCh {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  object-fit: cover;
  border-radius: 50%;
}

.selectTrophy {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selectTrophy select {
  width: 100%;
  margin: 5px 0;
}

.s:first-of-type {
  background-color: white;
  margin: 15px 0 5px 0;
}

.setting {
  background-color: transparent;
  font-size: 160%;
}

.IconSetting {
  background-color: transparent;
}

/* Setting  */
.SettingFon {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: red;
  z-index: 101;
  position: fixed;
  width: 85%;
  height: 50%;
  top: 100px;
  left: 50%;
  transform: translate(-50%);
}

.SettingBody {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
}

.AllSubs {
  overflow-x: scroll;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
}
@media (min-width: 700px) {
  /* Стили для экранов шире 700px */
  .SettingFon {
    width: 400px;
  }
}

.avatarsSubs {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 5px 0 0;
}

.mapSubsShow {
  display: flex;
  justify-content: baseline;
  align-items: center;
  width: 96%;
  margin: 5px 0;
  padding: 2%;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.nameSubs {
  font-size: 90%;
  text-align: left;
  background-color: transparent;
}
.loginSubs {
  font-size: 10px;
  color: gray;
  background-color: transparent;
}

.resultSubs {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.dateSubs {
  font-size: 90%;
  background-color: transparent;
}

.SettingBody div {
  background-color: transparent;
  font-size: 130%;
  margin: 18px 0;
}

.settingAbsolut {
  background-color: #00000057;
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
}

.red {
  color: red;
}

.green {
  color: green;
}

.plase {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plaseChamp {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  background-color: transparent;
}

.plaseChamp div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px 0 0;
  background-color: transparent;
}

.plaseChamp div div {
  color: #b5b5b5;
  font-size: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.pCh {
  fill: #b5b5b5;
  background-color: transparent;
}

.ass {
  display: flex;
  align-items: center;
  justify-content: center;
}

.shopOpen {
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectParticipant {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.selectParticipant div {
  width: 100%;
  display: flex;
  justify-content: baseline;
  align-items: center;
}

.selectParticipant div div {
  width: auto;
  padding: 5px 10px;
}

.kgParticipant {
  display: flex;
  flex-wrap: wrap;
}

.kgParticipant a {
  background-color: #18c0dd;
  color: white;
  margin: 5px 5px;
  padding: 15px;
  border-radius: 5px;
}

.ChildParticipant {
  padding: 0 0 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 99%;
}

.ChildParticipant div {
  border: 1px solid #b5b5b56d;
  padding: 7px 0;
  margin: 10px 0;
  width: 99%;
}

.bodyParticipant {
  position: relative;
  width: 100%;
  height: 100%;
}

.selectMyChild {
  border-radius: 0;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #b5b5b55d;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.selChild {
  position: fixed;
  top: 10%;
  left: 5%;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  padding: 20px 0;
  width: 90%;
  z-index: 11;
  height: 70%;
  overflow: auto;
}

.selChild div {
  margin: 10px;
  display: flex;
  justify-content: baseline;
  align-items: center;
}

.selectClass {
  border: 2px solid blue;
}

#selectID {
  border: 2px solid red;
}

.SelectHeader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0 30px 0;
}

.SelectHeader div {
  background-color: #03748e;
  font-weight: 700;
  color: white;
  padding: 10px;
  cursor: pointer;
  transition: 0.5s;
  border-radius: 5px;
}

.SelectHeader div:hover {
  background-color: #00b6de;
}

.headYearKg {
  margin: 10px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.headYearKg div {
  color: white;
  font-size: 110%;
  font-weight: 700;
  background-color: red;
  transform: skew(-10deg);
  padding: 7px;
  border-radius: 5px;
}

.CreateSetka {
  padding: 0 0 100px 0;
}

.showSetka {
  position: relative;
  padding: 0 0 100px 0;
}

.HeaderGridNumUl {
  display: flex;
  justify-content: baseline;
  align-items: center;
  overflow-x: scroll;
}

.HeaderGridNum {
  padding: 3px 10px;
  margin: 0 5px;
  border: 2px solid #000000;
  border-radius: 5px;
}

.showSetka1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: scroll;
  margin: 5px 0px 70px 0px;
}

#HeaderGridTrue {
  background-color: #000000;
  color: white;
}

.showSetka div {
  display: flex;
  justify-content: baseline;
  align-items: center;
  width: 100%;
  background-color: transparent;
  margin: 10px 0 5px 0;
}

.showSetka span {
  background-color: transparent;
  font-size: 110%;
}

.showSetka span:nth-child(2) {
  background-color: #8fb8c1;
  width: 20px;
}

.showSetka span:nth-child(1) {
  background-color: #f6f6f6;
  width: 30px;
  height: 100%;
  padding: 0;
}

.showSetka span:nth-child(3) {
  background-color: #cfd7da;
  width: 200px;
  height: 100%;
}

.down {
  display: block;
  width: 10px;
  background-color: #000000;
}

.containerSelect {
  width: 100%;
}

.selectClass {
  background-color: #cfd7da;
  width: 100%;
  height: 36px;
}

.dataSelect {
  margin: 0;
  width: 98.8%;
  background-color: transparent;
  border: 2px solid blue;
  border-top: #cfd7da;
  text-align: start;
  padding: 2px 3px;
  color: #0000007e;
  font-size: 60%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.adressPart {
  display: flex;
  justify-content: baseline;
  align-items: center;
}

.partGrid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.partGrid div {
  margin: 0 10px 0 0;
}

.subsInput {
  width: 100%;
  padding: 0 5px;
  margin: 4px 0px;
}

#orange {
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgb(8, 173, 134) 0%, green 58%);
  width: 100%;
}

#gray {
  background: rgb(193, 193, 193);
  background: linear-gradient(
    135deg,
    rgb(219, 218, 218) 0%,
    rgb(117, 116, 116) 58%
  );
  width: 100%;
  font-size: 120%;
  color: white;
  border-radius: 5px;
  padding: 3px;
}

#iconHeadedr {
  fill: white;
}

#avaClubChamp {
  margin: 0;
  width: 35px;
  height: 35px;
}
.nameClubChamp {
  display: flex;
  justify-content: center;
  align-items: center;
}
.myResultName {
  text-align: center;
  font-size: 110%;
  font-weight: 800;
}
