.labelForm {
  position: relative;
  margin: 0;
}

#registerInput {
  padding: 15px 7px 10px 7px;
  border-radius: 5px;
  background-color: white;
  margin: 10px;
  font-size: 120%;
}
.registerInputDiv {
  color: rgb(146, 146, 146);
  background-color: transparent;
  position: absolute;
  top: 12px;
  left: 25px;
  font-size: 72%;
}

.regErr p {
  color: red;
  margin: 0 0 5px 0;
  font-size: 100%;
}

#submitReg {
  padding: 5px 40px;
  margin: 10px;
  font-size: 150%;
}

#submitIsValid {
  background: linear-gradient(
    135deg,
    rgb(101, 221, 153) 0%,
    rgb(32, 142, 96) 34%
  );
}
