.ContainerExercises {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 10px 0;
}

.addExercist {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.addExercist h3 {
    color: rgb(187, 70, 245);
    margin: 5px 0;
}

#h3Exer {
    margin: 30px 0 0 0;
}

.addExercist input[type='text'],
.addExercist input[type='number'] {
    width: 60%;
    border-radius: 10px;
    padding: 4px;
    border: 2px solid rgb(133, 56, 156);
    margin: 5px 0;
}

.addExer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.ExercisesName {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: 10px 0 0 0;
    background-color: transparent;
    background: linear-gradient(135deg, rgba(155, 155, 155, 0.151) 0%, rgba(111, 111, 111, 0.151) 58%);
    border-radius: 5px;
    padding: 10px 0;
}

#ExercisesName {
    border: 1px solid rgb(0, 122, 128);
}

.ExercisesName div {
    color: rgb(133, 137, 137);
    font-weight: bold;
    background-color: transparent;
}

.inputsExer {
    display: flex;
    justify-content: baseline;
    align-items: center;
    background-color: transparent;
}



#deleteExer {
    background-color: transparent;
    color: red;
    background: none;
    font-size: 90%;
}

#dExer {
    background-color: transparent;
    color: rgb(118, 124, 147);
    background: none;
    font-size: 90%;
}

#saveExer {
    color: green;
    background-color: transparent;
    background: none;
    font-size: 90%;
}

.inputsExer input {
    margin: 0;
}

.continerRadioExer {
    margin: 7px 0;
}

.radioExer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px 0;
}

.radioExer div:nth-child(1) {
    color: rgb(97, 12, 133);
    margin: 0 15px 0 0;
}

.ContinerAssocExer {
    width: 95%;
    background-color: transparent;
    background: linear-gradient(135deg, rgba(205, 205, 205, 0.151) 0%, rgba(95, 95, 95, 0.151) 58%);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px 0;
    padding: 10px 0;
    border-radius: 10px;
}

.ContinerAssocExer div {
    background-color: transparent;
}

.AssocName {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}

.arrow {
    background-color: transparent;
}

.squats {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 5px 0 0 0;
}

.squats div:nth-child(1) {
    margin: 0 30px 0 0;
}

.squats div {
    font-weight: 300;
    color: black;
}

.ExercisesNameCoach {
    margin: 0 0 10px 0;
}

.ExercisesName input[type='number'] {
    width: 35px;
    border-radius: 5px;
    padding: 2px;
    border: 2px solid rgb(133, 56, 156);
    margin: 5px 0;
}

.checkSquats {
    fill: rgb(97, 155, 39);
    background-color: transparent;
    margin: 0 0 0 10px;
}

#maxSquats {
    font-size: 90%;
    color: rgb(102, 102, 102);
    font-weight: 80;
}

.staticExer {
    width: 90%;
    margin: 5px 0;
    background-color: rgba(139, 139, 139, 0.151);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerMapStatic {
    overflow-x: scroll;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 96%;
}

.staticExer div {
    background-color: transparent;
}

#staticBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    width: 16px;
}

#yStatic {
    height: 100px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

/* #yBlock {
    background-color: rgb(234, 69, 19);
    width: 1px;
} */

#ulDate {
    background-color: transparent;
    margin: 5px 0;
    padding: 5px 0;
    width: 98%;
    overflow-x: scroll;
    display: flex;
    justify-content: baseline;
    align-items: center;
}

#ulDate li {
    white-space: nowrap;
    background-color: rgb(192, 192, 192);
    border-radius: 10px;
    padding: 3px;
    margin: 0 3px;
}

#liSelSquats {
    background-color: transparent;
    background: linear-gradient(135deg, rgba(204, 204, 204, 0.281) 0%, rgba(0, 255, 89, 0.292) 58%);
}

#xBlock {
    font-size: 90%;
}

.ur7 {
    border-bottom: 1px solid rgb(64, 11, 116);
}

#squatsResult {
    position: relative;
    width: 0px;
    height: 0px;
    border-radius: 50%;
    background-color: red;
}

#lineSquats {
    left: 50%;
    position: absolute;
    background-color: rgb(234, 69, 19);
}

#char {
    position: absolute;
    background-color: rgb(234, 69, 19);
    width: 6px;
    height: 6px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

#squatsNumber {
    position: absolute;
    display: none;
    font-size: 80%;
    padding: 3px;
    background-color: white;
    border-radius: 0 5px 5px 5px;
    top: 6px;
    left: 6px;
    box-shadow: 0px 0px 8px 0px rgba(63, 34, 80, 0.419);
}

#char:hover #squatsNumber {
    display: block;
}

.nameHomework{
    display: flex;
    justify-content: baseline;
    align-items: center;
}

.nameHomework .position{
    font-size: 60%;
}