.containerSword {
  width: 100%;
  height: 1px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: end;
}
#DataBrackedContainerBlue {
  margin: 5px 0;
  width: 100%;
}
.timeBrackedChild {
  margin: 7px 0 7px 10px;
}
.move-animation {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: transparent;
  animation: move 10s infinite;
}
.moveD-animationD {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: transparent;
  animation: moveD 10s infinite;
}
.rotated-icon {
  background-color: rgba(255, 0, 0, 0.2);
  position: relative;
  width: 1px;
  height: 1px;
  transform: rotate(180deg);
  z-index: 10;
  right: 30px;
  top: 11px;
}
.rotated-icon2 {
  background-color: rgba(255, 0, 0, 0.2);
  position: relative;
  width: 1px;
  height: 1px;
  transform: rotate(90deg);
  z-index: 10;
  right: 32px;
  top: -11px;
}

@keyframes move {
  0% {
    transform: translate(0, 0) rotate(0deg); /* Начальная позиция */
  }
  92% {
    transform: translate(0, 0);
  }
  94% {
    transform: translate(40px, 40px) rotate(-90deg); /* Перемещение на 200px по обеим осям */
  }
  96% {
    transform: translate(0, 0);
  }
  98% {
    transform: translate(20px, 20px);
  }
  100% {
    transform: translate(0, 0) rotate(0deg); /* Возврат в начальное положение */
  }
}
@keyframes moveD {
  0% {
    transform: translate(0, 0) rotate(0deg); /* Начальная позиция */
  }
  92% {
    transform: translate(0, 0);
  }
  94% {
    transform: translate(40px, 40px) rotate(90deg); /* Перемещение на 200px по обеим осям */
  }
  96% {
    transform: translate(0, 0);
  }
  98% {
    transform: translate(20px, 20px);
  }
  100% {
    transform: translate(0, 0) rotate(0deg); /* Возврат в начальное положение */
  }
}

.container_current_fight_minut {
  background-color: grey;
  border-radius: 7px;

  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.container_current_fight_minut main {
  background-color: transparent;
  padding: 7px;
  color: white;
}

.current_fight_minut {
  background-color: green;
  padding: 5px;
  font-size: 110%;
  font-weight: 700;
  color: white;
  border-radius: 5px;
  transform: skew(-10deg);
}
