.participantMapContainer {
  position: relative;
  display: flex;
  justify-content: baseline;
  align-items: center;
  border-radius: 10px;
  border: 0px solid grey;
}
#participantAva {
  margin: 0 0 0 7px;
}
.participantFlag {
  background-color: transparent;
  position: absolute;
  top: 0px;
  right: 5px;
}
.allowAdmin {
  background-color: green;
  color: white;
  padding: 3px;
  border-radius: 5px;
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.dataParticipant {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.ParticipantCheckmark {
  z-index: 9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
  position: absolute;
  padding: 2px;
  bottom: -14px;
  left: 40px;
}
.adressParti {
  color: rgba(100, 100, 100, 0.593);
  font-size: 70%;
}
.coachDataParti {
  font-size: 70%;
  color: green;
}
.dataParticipant span {
  margin: 2px 0;
}
.dataParticipant {
  margin: 0 0 0 5px;
}
.nameDataPart {
  color: rgb(12, 85, 12);
  font-size: 80%;
  font-weight: 700;
}
.loginDataParti {
  color: green;
  font-size: 80%;
  border-bottom: 1px solid black;
}
.yearDataParti {
  color: green;
  font-size: 70%;
}
