.nameIcon {
  color: rgb(24, 192, 221);
  background-color: white;
  width: 100%;
  text-align: center;
  padding: 5px 0;
  font-weight: 800;
  box-shadow: 0px -3px 8px 0px rgba(34, 60, 80, 0.2);
  z-index: 2;
}
