.search-container {
  margin: 20px;
  position: relative;
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
}

.search-container input {
  width: 80%;
  padding: 10px 2%;
  border: 2px solid #ccc;
  border-radius: 8px;
  outline: none;
  transition: 0.3s;
}

.search-container.focused input {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.clear-btn {
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  font-size: 16px;
  cursor: pointer;
}

.results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  padding: 0;
  list-style: none;
  max-height: 250px;
  overflow-y: auto;
}

.user-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background 0.2s;
  width: 100%;
}

.user-item:hover {
  background: #f1f1f1;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.user-info {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  background-color: transparent;
}

.name {
  font-size: 14px;
  font-weight: bold;
  background-color: transparent;
}

.login {
  font-size: 12px;
  color: gray;
  background-color: transparent;
}

.no-result {
  color: #888;
  text-align: center;
  padding: 10px;
}
