.children {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.coachUl {
    display: flex;
    justify-content: space-around;
    background-color: white;
    padding: 10px 0;
    margin: 0;
    width: 100%;
}

.coachUl li {
    background-color: transparent;
    font-size: 110%;
    cursor: pointer;
}

.coachUl li:hover {
    color: rgb(24, 192, 221);
}

.clickLI {
    color: rgb(24, 192, 221);
}

.childData {
    width: 95%;
    position: relative;
    border-radius: 5px;
    display: flex;
    justify-content: baseline;
    align-items: center;
    margin: 10px 0;
    padding: 10px 5px;
    font-size: 130%;
    background-color: transparent;
    background: linear-gradient(135deg, rgba(222, 178, 76, 0.151) 0%, rgba(130, 130, 130, 0.151) 58%);
}

.loginLink {
    background-color: transparent;
    font-weight: bold;
    padding: 0px 0;
    color: rgb(3, 145, 98);
    border-bottom: 1.5px solid rgb(3, 145, 98);
}

.childText {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: 0 10px;
    width: 60%;
    text-align: start;
}

.childText div {
    background-color: transparent;
}

#buttonPayment {
    padding: 3px 5px;
    margin: 8px 0 0 0;
}

#paymentNum {
    width: 47px;
    padding: 5px;
    margin: 0 8px 0 0;
    border-radius: 7px;
}

#paymentComment {
    width: 120px;
    padding: 5px;
    border-radius: 7px;
}

.paymentDiv {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    font-size: 80%;
    background-color: transparent;
}

.check {
    margin: 0 0 0 10px;
    background-color: transparent;
    border-bottom: 1px solid black;
    cursor: pointer;
}

.checkDown {
    font-size: 60%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 5px 0 0 0;
}

.checkMonth {
    text-align: center;
    color: rgb(3, 145, 98);

}

.checkMonthRed {
    color: red;
}

#TwotoneDeleteChild {
    position: absolute;
    top: 5px;
    right: 5px;
}
#TwotoneAddChild {
    position: absolute;
    top: 5px;
    right: 30px;
}

.checkAllChild {
    font-size: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    border: 1px solid orangered;
    width: 80%;
    padding: 10px;
    margin: 0 0 20px 5%;
    background-color: rgba(255, 166, 0, 0.16);
    border-radius: 10px 0 10px 10px;
}

.checkAllChild main {
    background-color: transparent;
}

#checkPay {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.checkT{
    background-color: transparent;
}


.dateCheck {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 60%;
    color: black;
}

.timeCheck {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 60%;
    color: black;
}

.deleteCheck {
    font-size: 60%;
    color: red;
    cursor: pointer;
    margin: 10px 0 0 0;
}

#checkComment {
    padding: 5px;
    border-radius: 5px;
    background-color: rgba(255, 119, 0, 0.368);
    font-size: 80%;
    margin: 5px 0 0 0;
    text-align: left;
}

#checkPayment {
    background-color: rgb(3, 145, 98);
    color: white;
    border-radius: 5px;
    padding: 2px 5px;
    margin: 0 0 0 10px;
}
#checkPaymentRed{
    background-color: rgb(201, 52, 52);
    color: white;
    border-radius: 5px;
    padding: 2px 5px;
    margin: 0 0 0 10px;
}
#avaCheck{
    margin: 0;
    width: 50px;
    height: 50px;
}
.containerData{
    display: flex;
    justify-content: baseline;
    align-items: center;
    background-color: transparent;
    padding: 0;
}
.dataName{
    margin: 0 0 0 10px;
}
.containerPayment{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.containerDate{
    display: flex;
    flex-direction: center;
    align-items: center;
}
.containerDate main{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px 0 0;
}
.containerDate main input{
    margin: 0 0 0 5px;
}
.paymentDataContainer{
    width: 95%;
    position: relative;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
    padding: 10px 5px;
    font-size: 130%;
    background-color: transparent;
    background: linear-gradient(135deg, rgba(222, 178, 76, 0.151) 0%, rgba(130, 130, 130, 0.151) 58%);
}
.paymentData{
    background-color: transparent;
    display: flex;
    justify-content: baseline;
    align-items: center;
    width: 100%;
}
#avaCheck2{
    margin: 0;
    width: 30px;
    height: 30px;
}
.ChekContainer{
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 70%;
}
.posithinUserPayment{
    background-color: transparent;
}