.reportChampionship {
  margin: 10px;
  padding: 10px;
  background: linear-gradient(
    32deg,
    rgb(183, 183, 183) 0%,
    rgb(243, 243, 243) 57%,
    rgb(255, 255, 255) 100%
  );
  box-shadow: 0px -3px 8px 0px rgba(34, 60, 80, 0.2);
  border-radius: 7px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.reportChampionship div {
  background-color: transparent;
}
.reportChampionship div:nth-child(1) {
  font-weight: 700;
}
