.sportName {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.395);
  padding: 0 0 5px 0;
}
.champName {
  font-weight: 800;
  color: black;
  padding: 0 0 5px 0;
}
.ball {
  background-color: rgba(255, 0, 0, 0.697);
  border-radius: 5px;
  font-weight: 700;
  transform: skew(-10deg);
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.MyGridPlace {
  color: white;
  background-color: transparent;
}
