.categoriesChild {
  display: flex;
  justify-content: baseline;
  align-items: center;
}
.categoriesChild span {
  border-radius: 5px;
  background-color: dimgray;
  color: white;
  padding: 3px 5px;
  margin: 0 5px 0 0;
  font-size: 70%;
}
