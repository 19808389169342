.coachMap {
  border: 1px solid black;
  display: flex;
  justify-content: baseline;
  align-items: center;
  background-color: #efefef;
  border-radius: 5px;
  margin: 5px 10px;
}
.coachMap div {
  background-color: transparent;
}
.BiSolidMedalGold {
  fill: rgb(226, 192, 0);
}
.medalCoach {
  display: flex;
  justify-content: center;
  align-items: center;
}
.BiSolidMedalSilver {
  fill: silver;
}
.BiSolidMedalBronze {
  fill: darkgoldenrod;
}
.medalCoachContainer {
  display: flex;
  justify-content: baseline;
  align-items: center;
  font-weight: 1000;
  font-size: 90%;
}
.BiSolidMedal {
  margin: 0 0 0 10px;
  font-size: 200%;
  background-color: transparent;
}
.coachName {
  font-weight: 700;
  display: flex;
  justify-content: baseline;
  align-items: center;
  padding: 10px 0 0 5px;
}
#avaCoach {
  margin: 10px;
  width: 50px;
  height: 50px;
}
.medalAndName {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.childCoach {
  font-size: 150%;
  fill: rgb(0, 79, 153);
}
.childCoachLength {
  margin: 0 0 0 3px;
  color: rgb(0, 79, 153);
}
.coachIndex {
  margin: 0 0 0 6px;
  font-weight: 700;
}
