.container_time {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.formTime {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 10px 0;
}
.cord_date_time {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cord_start_time {
  position: sticky;
  top: 10px;
  padding: 5px;
  width: 80%;
  font-size: 13px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(186, 186, 186, 0.3);
  font-family: Arial, sans-serif;
  text-align: center;
  z-index: 10;
}
.cord_start_time div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: white;
}
.IconCord {
  background-color: transparent;
  fill: white;
  padding: 0 5px 0 0;
}
.containerMatches {
  width: 100%;
}
#childFightClick {
  background: rgb(3, 77, 11);
  background: linear-gradient(
    32deg,
    rgba(3, 77, 11, 1) 0%,
    rgba(9, 121, 36, 1) 57%,
    rgba(26, 184, 154, 1) 100%
  );
  color: white;
  border: 0px solid green;
  padding: 7px 12px;
}

#childFight {
  border-color: green;
  color: darkgreen;
  padding: 5px 10px;
}
#childFight:hover {
  background-color: rgba(0, 128, 0, 0.452);
  color: white;
}
.swordChampTime {
  font-size: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.224);
  border-radius: 5px;
  padding: 2px;
  margin: 0 0 0 6px;
}
.cordLuSwords {
  background-color: transparent;
  color: white;
}
#clickSwordChampTime {
  background-color: rgb(222, 222, 222);
}
.showGrid {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: scroll;
  margin: 5px 0px 10px 0px;
}
