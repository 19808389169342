.grade {
  width: 100%;
}

.onHeaderLi {
  color: rgb(24, 192, 221);
}

.removeChildrens {
  display: flex;
  justify-content: baseline;
  align-items: center;
  white-space: nowrap;
  padding: 0 0 5px 0;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 20px;
  padding: 5px 30px;
  margin: 5px 0;
}

.ChBlue #removeChildrens a {
  font-size: 110%;
}

.removeChildrens h3 {
  color: rgb(103, 97, 97);
  background-color: transparent;
  margin: 0;
}

#ChBlue {
  background-color: rgb(181, 235, 219);
  border-color: rgb(181, 235, 219);
}

.trOptions {
  background-color: transparent;
}

.TwotoneDelete {
  background-color: transparent;
  margin: 0 0 0 20px;
}

.childClass {
  padding: 20px 0 150px 0;
}

.childrenGrade {
  padding: 0 0 40px 0;
}

.MdGrade {
  font-size: 180%;
}

.MdGradeBig {
  font-size: 180%;
  background-color: transparent;
}

.MdGradeBigBoss {
  font-size: 220%;
}

.classSubject {
  padding: 3px 10px;
  /* background-color: rgba(164, 235, 240, 0.496); */
  border: 3px solid rgba(33, 219, 233, 0.515);
  margin: 5px 6px;
  position: relative;
  border-radius: 15px;
  display: flex;
  flex-wrap: nowrap;
  font-size: 90%;
  cursor: pointer;
}

#SlArrowDown {
  margin: 1px 0 0 15px;
  background-color: transparent;
  text-align: center;
}

.classSubject h3 {
  margin: 0;
  background-color: transparent;
  color: rgb(103, 97, 97);
  white-space: nowrap;
  font-size: 95%;
}

.redChildClass {
  color: red;
}

.GrenCoach {
  color: green;
}

.chGradeName {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chGradeName div {
  font-size: 130%;
  margin: 0 20px 0 0;
}

.showAllGrade {
  padding: 0 0 200px 0;
}

.selectGrade {
  overflow-x: auto;
  display: flex;
  justify-content: baseline;
  align-items: baseline;
  background-color: rgb(243, 243, 243);
  width: 100%;
}

.tableGrade {
  overflow-x: scroll;
  width: 97%;
  margin: 7px 0;
}

table {
  background-color: rgba(33, 219, 233, 0.514);
}

thead th {
  padding: 5px;
  text-align: center;
  align-items: center;
}

.theadName {
  white-space: nowrap;
  padding: 10px;
}

.tbodyName {
  white-space: nowrap;
  padding: 10px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.green {
  color: rgb(6, 159, 6);
}

.Star {
  display: flex;
  justify-content: center;
  align-items: center;
}

#N {
  font-size: 140%;
  font-weight: bold;
  margin: 0 0 0 30px;
  cursor: pointer;
}

.Nred {
  color: red;
}

.NYellow {
  color: rgb(219, 229, 107);
}

tbody,
tbody tr {
  background-color: transparent;
}

.grade7 {
  color: rgb(6, 159, 6);
  text-align: start;
  font-weight: 500;
  font-size: 80%;
}

#th {
  padding: 0 5px;
}

.LabelTime {
  display: flex;
  justify-content: center;
  align-items: center;
}

.LabelTime div {
  font-size: 140%;
}
.iconGradContainer {
  background-color: transparent;
}
.Checkmark {
  fill: rgb(3, 145, 98);
  font-size: 130%;
}
.penGrad {
  fill: rgb(127, 74, 5);
}
.cancelRed {
  fill: red;
}
.trNameS {
  position: relative;
}
.thTextName {
  width: 100px;
}
.thNameS {
  background-color: rgba(255, 255, 255, 0.865);
  position: absolute;
  left: 2px;
  padding: 10px;
  width: 100px;
}
.thNameSDublicate {
  background-color: transparent;
  color: transparent;
  width: 100px;
}

.selectCoachAndAss {
  display: flex;
  justify-content: baseline;
  align-items: center;
  background-color: transparent;
}
.selectCoachAndAss h3 {
  margin: 0 0 0 10px;
}
#avaCheck30 {
  margin: 0;
  width: 30px;
  height: 30px;
}
